import { PermissionCheckerService } from '@abp/auth/permission-checker.service';
import { AppSessionService } from '@shared/common/session/app-session.service';

import { Injectable } from '@angular/core';
import { AppMenu } from './app-menu';
import { AppMenuItem } from './app-menu-item';

@Injectable()
export class AppNavigationService {

    constructor(
        private _permissionCheckerService: PermissionCheckerService,
        private _appSessionService: AppSessionService
    ) {

    }

    getMenu(): AppMenu {
        return new AppMenu('MainMenu', 'MainMenu', [
            new AppMenuItem('Dashboard', 'Pages.Administration.Host.Dashboard', 'flaticon-line-graph', '/app/admin/hostDashboard'),

            new AppMenuItem('Dashboard', 'Pages.Tenant.Dashboard', 'flaticon-line-graph', '/app/main/dashboard'),
            new AppMenuItem('Tenants', 'Pages.Tenants', 'flaticon-list-3', '/app/admin/tenants'),

            new AppMenuItem('Companies', 'Farmers.Companies', 'flaticon-suitcase', '/app/main/companies/companies'),
            new AppMenuItem('Farms', 'Farmers.Farms', 'flaticon-map-location', '/app/main/farms/farms'),
            
            new AppMenuItem('DiseaseSurveillance', '', 'flaticon-layers', '', [
                new AppMenuItem('TestPersons', 'Farmers.TestPersons', 'flaticon-users', '/app/main/testPersons/testPersons'),
                new AppMenuItem('ResidueMonitorTests', 'Pages.PRMTests', 'flaticon-analytics', '/app/main/prm/prmTests'),
                new AppMenuItem('ManageLabTestResults', 'Farmers.LabTestResultsAI', 'flaticon-diagram', '/app/main/labTestResultsAI/labTestResultsAI'),
            ]),

            new AppMenuItem('TraderCompanies', 'Traders.TraderCompanies', 'flaticon-truck', '/app/main/traders/traders'),
            new AppMenuItem('CompanyRecons', 'Traders.Recons', 'flaticon-list-3', '/app/main/recons/recons'),

            new AppMenuItem('BackOffice', 'BackOffice', 'flaticon-layers', '', [
                new AppMenuItem('Countries', 'BackOffice.Countries', 'flaticon-earth-globe', '/app/admin/countries/countries'),
                new AppMenuItem('Provinces', 'BackOffice.Provinces', 'flaticon-map', '/app/admin/provinces/provinces'),
                new AppMenuItem('Areas', 'BackOffice.Areas', 'flaticon-share', '/app/admin/areas/areas'),
                new AppMenuItem('Towns', 'BackOffice.Towns', 'flaticon-buildings', '/app/admin/towns/towns'),

                new AppMenuItem('FarmTypes', 'BackOffice.FarmTypes', 'flaticon-squares-4', '/app/admin/farmTypes/farmTypes'),

                new AppMenuItem('DiseaseSurveillance', '', 'flaticon-layers', '', [
                    new AppMenuItem('TestPersonTypes', 'BackOffice.TestPersonTypes', 'flaticon-users', '/app/admin/testPersonTypes/testPersonTypes'),
                    new AppMenuItem('LabTests', 'BackOffice.LabTests', 'flaticon-diagram', '/app/main/labTests/labTests'),
                    new AppMenuItem('SubstanceGroups', 'BackOffice.SubstanceGroups', 'flaticon-list-2', '/app/main/prm/substanceGroups'),
                    new AppMenuItem('Residues', 'BackOffice.Residues', 'flaticon-interface-11', '/app/main/prm/residues'),
                ]),

                //new AppMenuItem('Documents', 'Pages.Administration.Documents', 'flaticon-list', '/app/admin/documents/documents'),
            ]),

            //new AppMenuItem('BinaryObjects', 'Pages.BinaryObjects', 'flaticon-more', '/app/main/storage/binaryObjects'),
            //new AppMenuItem('PurchaseRecons', 'Traders.PurchaseRecons', 'flaticon-more', '/app/main/purchaseRecons/purchaseRecons'),
            //new AppMenuItem('DistributionRecons', 'Traders.DistributionRecons', 'flaticon-more', '/app/main/distributionRecons/distributionRecons'),



            new AppMenuItem('Administration', '', 'flaticon-interface-8', '', [
                new AppMenuItem('OrganizationUnits', 'Pages.Administration.OrganizationUnits', 'flaticon-map', '/app/admin/organization-units'),
                new AppMenuItem('Roles', 'Pages.Administration.Roles', 'flaticon-suitcase', '/app/admin/roles'),
                new AppMenuItem('Users', 'Pages.Administration.Users', 'flaticon-users', '/app/admin/users'),
            new AppMenuItem('Restrictions', 'Pages.Administration.Restrictions', 'flaticon-globe', '/app/admin/restrictions/restrictions'),
            

                new AppMenuItem('Languages', 'Pages.Administration.Languages', 'flaticon-tabs', '/app/admin/languages'),
                new AppMenuItem('AuditLogs', 'Pages.Administration.AuditLogs', 'flaticon-folder-1', '/app/admin/auditLogs'),
                new AppMenuItem('Maintenance', 'Pages.Administration.Host.Maintenance', 'flaticon-lock', '/app/admin/maintenance'),
                //new AppMenuItem('Subscription', 'Pages.Administration.Tenant.SubscriptionManagement', 'flaticon-refresh', '/app/admin/subscription-management'),
                //new AppMenuItem('VisualSettings', 'Pages.Administration.UiCustomization', 'flaticon-medical', '/app/admin/ui-customization'),
                //new AppMenuItem('Settings', 'Pages.Administration.Host.Settings', 'flaticon-settings', '/app/admin/hostSettings'),
                //new AppMenuItem('Settings', 'Pages.Administration.Tenant.Settings', 'flaticon-settings', '/app/admin/tenantSettings')
            ]),
            //new AppMenuItem('DemoUiComponents', 'Pages.DemoUiComponents', 'flaticon-shapes', '/app/admin/demo-ui-components')
        ]);
    }

    checkChildMenuItemPermission(menuItem): boolean {

        for (let i = 0; i < menuItem.items.length; i++) {
            let subMenuItem = menuItem.items[i];

            if (subMenuItem.permissionName === '' || subMenuItem.permissionName === null || subMenuItem.permissionName && this._permissionCheckerService.isGranted(subMenuItem.permissionName)) {
                return true;
            } else if (subMenuItem.items && subMenuItem.items.length) {
                return this.checkChildMenuItemPermission(subMenuItem);
            }
        }

        return false;
    }

    showMenuItem(menuItem: AppMenuItem): boolean {
        if (menuItem.permissionName === 'Pages.Administration.Tenant.SubscriptionManagement' && this._appSessionService.tenant && !this._appSessionService.tenant.edition) {
            return false;
        }

        let hideMenuItem = false;

        if (menuItem.requiresAuthentication && !this._appSessionService.user) {
            hideMenuItem = true;
        }

        if (menuItem.permissionName && !this._permissionCheckerService.isGranted(menuItem.permissionName)) {
            hideMenuItem = true;
        }

        if (this._appSessionService.tenant || !abp.multiTenancy.ignoreFeatureCheckForHostUsers) {
            if (menuItem.hasFeatureDependency() && !menuItem.featureDependencySatisfied()) {
                hideMenuItem = true;
            }
        }

        if (!hideMenuItem && menuItem.items && menuItem.items.length) {
            return this.checkChildMenuItemPermission(menuItem);
        }

        return !hideMenuItem;
    }
}
